/* eslint-disable @next/next/no-img-element */
'use client';

import clsx from 'clsx';
import parse from 'html-react-parser';
import Script from 'next/script';
import React, { useEffect, useRef, useState } from 'react';

import { getZoneImage } from '@/utils/ads/getZoneImage';
import { triggerURL } from '@/utils/ads/triggerURL';
import { ModalPlacementInterface } from '@/utils/helpers/types';

import { AD_SIZES_HASH, AdType } from './types';

// Regular expressions to match style, script, and text (html) in between
const styleRegex = /<style>(.*?)<\/style>/s; // 's' flag enables the dot (.) to match newline characters
const scriptRegex = /<script>(.*?)<\/script>/s;
const betweenRegex = /<\/style>(.*?)<script>/s;

// Function to extract matched substrings
function extractParts(input: string) {
	const styleMatch = input.match(styleRegex);
	const scriptMatch = input.match(scriptRegex);
	const htmlMatch = input.match(betweenRegex);

	// Extracting matched substrings or returning empty string if not found
	const stylePart = styleMatch ? styleMatch[1] : '';
	const scriptPart = scriptMatch ? scriptMatch[1] : '';
	const htmlPart = htmlMatch ? htmlMatch[1] : '';

	return [stylePart, htmlPart, scriptPart];
}

export interface RenderModalAdProps {
	className?: string;
	height?: number;
	ipAddress?: string;
	pageId: number;
	place: number;
	zoneId: string;
	width?: number;
}

interface RenderModalAdInfo {
	image?: ModalPlacementInterface;
	pageId: number;
	place: number;
	viewableURLCalled: boolean;
	eligibleURLCalled: boolean;
}

const RenderModalAd: React.FC<RenderModalAdProps> = ({ className, height, ipAddress, pageId, place, zoneId, width }) => {
	const adRef = useRef<HTMLDivElement>(null);

	const placementId = `${zoneId}_${pageId}_${place}`;
	const [adInfo, setAdInfo] = useState<RenderModalAdInfo>({
		pageId: +pageId,
		place: +place,
		viewableURLCalled: false,
		eligibleURLCalled: false
	});

	useEffect(() => {
		const fetchImage = async (): Promise<boolean> => {
			const currentUrl = location.protocol + '//' + location.host + location.pathname;
			const adSizeInfo = AD_SIZES_HASH[zoneId as AdType];
			const adSize = width && height ? `${width}x${height}` : adSizeInfo ? adSizeInfo.size : '';
			const screenWidth = screen.width;
			const screenHeight = screen.height;
			const screenPixelRatio = window.devicePixelRatio;
			const userAgent = navigator.userAgent;

			const response = await getZoneImage({
				ipAddress: ipAddress,
				pageId: adInfo.pageId,
				place: adInfo.place,
				refferingURL: currentUrl,
				screenHeight: screenHeight,
				screenPixelRatio: screenPixelRatio,
				screenWidth: screenWidth,
				size: adSize,
				userAgent: userAgent,
				zoneId: zoneId
			});

			if (response.status === 200 && response.placement) {
				const fetchedImage: ModalPlacementInterface = response.placement;
				setAdInfo((prevAdInfo) => ({
					...prevAdInfo,
					image: fetchedImage,
					place: prevAdInfo.place + 1,
					viewableURLCalled: false,
					eligibleURLCalled: false
				}));

				return true; // ad zone image fetched successfully
			}

			return false; // ad zone image fetched successfully
		};

		if (!adInfo.image) {
			fetchImage();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (!adInfo.eligibleURLCalled) {
						if (adInfo.image?.eligible_url) {
							triggerURL(adInfo.image.eligible_url);
						}
						setAdInfo((prevAdInfo) => ({ ...prevAdInfo, eligibleURLCalled: true }));
					}

					// Check if 50% or more of the target element is visible
					if (entry.intersectionRatio >= 0.5) {
						if (!adInfo.viewableURLCalled) {
							if (adInfo.image?.viewable_url) {
								triggerURL(adInfo.image.viewable_url);
							}
							setAdInfo((prevAdInfo) => ({ ...prevAdInfo, viewableURLCalled: true }));
						}
					}
				});
			},
			{ threshold: 0.5 } // Set threshold to 0.5 (50% visibility)
		);

		const adElement = adRef.current;
		if (adElement) {
			observer.observe(adElement);
		}

		return () => {
			if (adElement) {
				observer.unobserve(adElement);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adInfo.image, adInfo.viewableURLCalled, adInfo.eligibleURLCalled]);

	if (!adInfo.image) {
		return null;
	}

	const classes = clsx('max-w-full overflow-hidden rounded-xl', className);

	const inputString = adInfo.image.body;

	// Extracting parts from the input string
	// eslint-disable-next-line prefer-const
	let [stylesPart, htmlPart, scriptPart] = extractParts(inputString);

	if (scriptPart) {
		scriptPart = scriptPart?.trim();

		const textToRemoveFromStart = `console.count();`;
		const textToRemoveFromStart2 = `document.addEventListener('DOMContentLoaded', function () {`;
		const textToRemoveFromEnd = `});`;

		if (scriptPart.startsWith(textToRemoveFromStart)) {
			scriptPart = scriptPart.substring(textToRemoveFromStart.length);
		}

		scriptPart = scriptPart?.trim();

		if (scriptPart.startsWith(textToRemoveFromStart2)) {
			scriptPart = scriptPart.substring(textToRemoveFromStart2.length);
		}

		// Remove from the end
		if (scriptPart.endsWith(textToRemoveFromEnd)) {
			scriptPart = scriptPart.slice(0, -textToRemoveFromEnd.length);
		}
	}

	return (
		<div id={placementId} className={classes} ref={adRef}>
			<style>{stylesPart}</style>
			<Script id="adbutler-modal-script" strategy="afterInteractive">
				{scriptPart}
			</Script>
			<div>
				{adInfo.image.is_redirectable && htmlPart ? (
					<a href={adInfo.image.redirect_url} target={adInfo.image.target} rel="nofollow">
						{parse(htmlPart)}
					</a>
				) : htmlPart ? (
					<>{parse(htmlPart)}</>
				) : null}
				{adInfo.image.accupixel_url && <img src={adInfo.image.accupixel_url} alt="" className="max-w-full" />}
			</div>
		</div>
	);
};

export default RenderModalAd;
