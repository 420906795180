'use client';

import type { Match } from '@pickleballinc/react-ui/types/Match';
import { useInfiniteQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useRTE } from '@/contexts/rte/RTEProvider';
import { RTEMatchInfo } from '@/contexts/rte/types';
import { TickerScroller } from '@/modules/ticker/TickerScroller';
import { TickerSummary } from '@/modules/ticker/TickerSummary';
import { MatchInfoV2, TickerDataInterface } from '@/modules/ticker/types';
import { COURT_PRIORITY } from '@/utils/helpers/constants';
import { formatTickerMatches } from '@/utils/helpers/formatTickerMatches';

const SIT_TIME_AFTER_COMPLETION = process.env.NEXT_PUBLIC_APP_ENV !== 'prod' ? 30000 : 300000;

export function Ticker({ initialMatches }: { initialMatches: TickerDataInterface }) {
	const { data } = useInfiniteQuery({
		queryKey: ['ticker'],
		queryFn: async ({ pageParam = 1 }) => {
			const response = await fetch(`/api/v2/ticker?current_page=${pageParam}`);
			const result: { data: TickerDataInterface; statusCode: number; error?: string } = await response.json();

			if (result.statusCode !== 200 || !result.data || result.data.matches.length === 0) return null;

			return {
				page: pageParam,
				data: result.data
			};
		},
		initialData: {
			pages: [
				{
					page: 1,
					data: initialMatches
				}
			],
			pageParams: [undefined]
		},
		refetchInterval: 3600000, // hard reload ticker matches every 60 minutes (needed because of midnight)
		refetchOnWindowFocus: false
	});

	const [matchesData, setMatchesData] = useState<MatchInfoV2[]>([]);
	const [filteredMatches, setFilteredMatches] = useState<Match[]>([]);
	const [totalRecords, setTotalRecords] = useState<number>(0);

	// when all matches are fetched data is updated, we need to update matchesData with matches that aren't in the first up to 5 prefetched matches
	useMemo(() => {
		setMatchesData((matchesPrev) => {
			const matchesToAdd: MatchInfoV2[] = [];

			// Only add matches that do not already exist in matchesPrev
			data?.pages.forEach((page) => {
				page?.data.matches.forEach((newMatch) => {
					if (matchesPrev.findIndex((match) => match.matchUuid === newMatch.matchUuid) === -1) {
						matchesToAdd.push(newMatch);
					}
				});
			});

			// Only update state if there are new matches to add
			if (matchesToAdd.length > 0) {
				return [...matchesPrev, ...matchesToAdd];
			}

			return matchesPrev; // If no new matches, return the current state to prevent re-renders
		});
	}, [data]);

	const { matches } = useRTE();
	const timeoutMapRef = useRef<Map<string, NodeJS.Timeout>>(new Map());

	useEffect(() => {
		setMatchesData((matchesPrev) => {
			const matchesDataCopy = [...matchesPrev];

			matches.forEach((newMatch) => {
				const matchIndex = matchesDataCopy.findIndex((match) => match.matchUuid === newMatch.matchUuid);

				if (matchIndex !== -1) {
					const matchToUpdate = matchesDataCopy[matchIndex];

					let matchMoved = false; // Flag to check if the match has been moved

					if (matchToUpdate) {
						// Check if at least one field in newMatch is different from matchToUpdate
						const hasDifferences = (Object.keys(newMatch) as (keyof RTEMatchInfo)[]).some(
							(key) => newMatch[key] !== matchToUpdate[key as keyof MatchInfoV2]
						);

						// Create a new object by removing any `undefined` values from `newMatch` and adding `changeHappened` only if there are differences
						const filteredNewMatch = Object.fromEntries(
							Object.entries({ ...newMatch, changeHappened: hasDifferences }).filter(([_, value]) => value !== undefined)
						);

						if (hasDifferences) {
							if (
								!matchToUpdate.localDateMatchAssignedToCourt &&
								!matchToUpdate.localDateMatchStart &&
								!matchToUpdate.localDateMatchCompleted &&
								filteredNewMatch.localDateMatchAssignedToCourt
							) {
								// 1. Match just assigned to the court - move it to the first index after all the matches that are live
								const lastLiveMatchIndex = matchesDataCopy.findLastIndex((match) => match.matchStatus === 2);
								const insertIndex = lastLiveMatchIndex !== -1 ? lastLiveMatchIndex + 1 : 0;

								// Remove match from its current position
								matchesDataCopy.splice(matchIndex, 1);
								// Insert match with updated fields in the correct position
								matchesDataCopy.splice(insertIndex, 0, {
									...matchToUpdate,
									...filteredNewMatch
								} as MatchInfoV2);

								matchMoved = true;
							} else if (matchToUpdate.matchStatus !== 2 && filteredNewMatch.matchStatus === 2) {
								// 2. Match just started, meaning match is now live - move it to a specific index at the start with the following criteria for where to place it: cc, gs, sc1, sc2, sc3, sc4, sc5, sc6

								const courtTitle = (filteredNewMatch.courtTitle as string) || matchToUpdate.courtTitle || '';

								// Find the priority index of the new live match's courtTitle
								const newMatchPriorityIndex = COURT_PRIORITY.indexOf(courtTitle.toLowerCase());

								let insertIndex: number;

								// Check if newMatchPriorityIndex is -1 (courtTitle not in priority list)
								if (newMatchPriorityIndex === -1) {
									// If so, place it after the last live match
									insertIndex = matchesDataCopy.findIndex((match) => match.matchStatus !== 2);
									insertIndex = insertIndex === -1 ? matchesDataCopy.length : insertIndex; // If no non-live matches, place at the end
								} else {
									// Determine the insertion index based on court title priority
									insertIndex = matchesDataCopy.findIndex((match) => {
										// Only consider live matches (matchStatus === 2)
										if (match.matchStatus === 2) {
											// Get the court priority index for the current live match
											const matchPriorityIndex = COURT_PRIORITY.indexOf(match.courtTitle?.toLowerCase() || '');

											// Check if the new match should come before the current live match
											return (
												matchPriorityIndex === -1 || // Current live match has an unlisted court title
												newMatchPriorityIndex < matchPriorityIndex // Insert if the new match has higher priority
											);
										}
										return false; // Non-live matches are ignored
									});

									// If no suitable insertIndex was found, place it after the last live match
									if (insertIndex === -1) {
										insertIndex = matchesDataCopy.findIndex((match) => match.matchStatus !== 2);
										insertIndex = insertIndex === -1 ? matchesDataCopy.length : insertIndex; // If no non-live matches, place at the end
									}
								}

								// Remove the match from its current position
								matchesDataCopy.splice(matchIndex, 1);
								// Insert the updated live match at the correct index
								matchesDataCopy.splice(insertIndex, 0, { ...matchToUpdate, ...filteredNewMatch } as MatchInfoV2);

								matchMoved = true;
							} else if (matchToUpdate.matchStatus !== 4 && filteredNewMatch.matchStatus === 4) {
								// Clear any existing timeout for this match to prevent duplicates
								const existingTimeout = timeoutMapRef.current.get(matchToUpdate.matchUuid);
								if (existingTimeout) {
									clearTimeout(existingTimeout);
								}

								// Schedule the match to move to the end of the list after 30 seconds
								const timeout = setTimeout(() => {
									setMatchesData((matchesPrev) => {
										const matchesDataCopy = [...matchesPrev];
										const matchIndex = matchesDataCopy.findIndex((match) => match.matchUuid === newMatch.matchUuid);

										if (matchIndex !== -1) {
											const [completedMatch] = matchesDataCopy.splice(matchIndex, 1);
											matchesDataCopy.push(completedMatch as MatchInfoV2);
										}

										return matchesDataCopy;
									});
									// Remove timeout from map after it executes
									timeoutMapRef.current.delete(matchToUpdate.matchUuid);
								}, SIT_TIME_AFTER_COMPLETION);

								// Add the new timeout to the map
								timeoutMapRef.current.set(matchToUpdate.matchUuid, timeout);
							}
						}

						// Ensure this update only happens if the match wasn’t moved
						if (!matchMoved) {
							matchesDataCopy[matchIndex] = { ...matchToUpdate, ...filteredNewMatch } as MatchInfoV2;
						}
					}
				}
			});

			return [...matchesDataCopy];
		});
	}, [matches]);

	// Clear all timeouts on component unmount
	useEffect(() => {
		return () => {
			timeoutMapRef.current.forEach((timeout) => clearTimeout(timeout));
			timeoutMapRef.current.clear();
		};
	}, []);

	useMemo(() => {
		let totalRecords = 0;

		data?.pages.forEach((page) => {
			if (page && page.data && page.data.matches && page.data.matches.length > 0) {
				totalRecords = page.data.totalRecords;
			}
		});

		const filteredMatchesNew = formatTickerMatches({
			matchInfos: matchesData,
			imagesWidth: 22,
			imagesHeight: 22
		});

		setTotalRecords(totalRecords);

		setFilteredMatches(filteredMatchesNew);
	}, [matchesData]);

	if (filteredMatches.length > 0) {
		return (
			<div className="bg-white" id="pb-ticker">
				<div className="mx-auto flex max-w-[1440px]">
					<div className="hidden sm:flex">
						<TickerSummary />
					</div>
					<TickerScroller results={filteredMatches} totalRecords={totalRecords} />
				</div>
			</div>
		);
	}

	return null;
}
