import { PlacementInterface } from '@/utils/helpers/types';

interface PlacementsInterface {
	placement_1?: PlacementInterface;
}

export interface SingleAdResponseInfo {
	status: string;
	placements: PlacementsInterface;
}

export interface ZoneImageRequestObject {
	ipAddress?: string;
	pageId: number;
	place: number;
	refferingURL: string;
	screenHeight?: number;
	screenPixelRatio?: number;
	screenWidth?: number;
	size: string;
	userAgent?: string;
	zoneId: string;
}

export interface MainContentNewsResponse {
	placement?: PlacementInterface;
	status: number;
}

// This function is called on client only
export async function getZoneImage(requestObject: ZoneImageRequestObject): Promise<MainContentNewsResponse> {
	const { zoneId, refferingURL, pageId, size, place, screenWidth, screenHeight, screenPixelRatio, userAgent, ipAddress } = requestObject;

	if (!zoneId || !refferingURL || pageId === undefined || !size || place === undefined) {
		return {
			status: 400
		};
	}

	const fetchURL = `https://servedbyadbutler.com/adserve/;ID=186107;setID=${zoneId};type=json;rf=1;referrer=${encodeURI(
		refferingURL
	)};pid=${pageId};place=${place}${size && `;size=${size}`}${ipAddress && `;ip=${ipAddress}`}${screenWidth && `;sw=${screenWidth}`}${
		screenHeight && `;sh=${screenHeight}`
	}${screenPixelRatio && `;spr=${screenPixelRatio}`}${userAgent && `;ua=${encodeURIComponent(userAgent)}`};`;

	try {
		const response = await fetch(fetchURL, {
			method: 'GET',
			cache: 'no-store' // no caching is allowed because of impressions count
		});

		if (response.ok) {
			const { status, placements }: SingleAdResponseInfo = await response.json();

			if (status.toLocaleUpperCase() === 'SUCCESS' && placements && placements.placement_1) {
				return {
					placement: placements.placement_1,
					status: 200
				};
			} else if (status.toLocaleUpperCase() === 'NO_ADS') {
				return {
					status: 404
				};
			} else {
				return {
					status: 400
				};
			}
		}

		return {
			status: response.status
		};
	} catch (e) {
		return {
			status: 404
		};
	}
}
